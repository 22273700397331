import { Component, OnInit } from '@angular/core';
import {IssueService} from '../../shared/service/issue.service';
import {MatIconRegistry} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-search-results-list',
  templateUrl: './search-results-list.component.html',
  styleUrls: ['./search-results-list.component.sass']
})
export class SearchResultsListComponent implements OnInit {

  private searched = false;
  private isLoading = false;
  private data = [];
  private searchQuery: string;

  constructor(private issueService: IssueService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'thumbs-up',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/like.svg'));

    iconRegistry.addSvgIcon(
      'thumbs-down',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/dislike.svg'));
  }

  ngOnInit() {
    this.issueService.cast.subscribe(search =>
    {
      this.isLoading = true;
      this.searchQuery = search;
      if (search.replace(/\s/g, "").length > 1) {
        this.issueService.getIssues(search).subscribe(
          (data) => {
            this.searched = true;
            this.isLoading = false;
            this.data = data['items'];
          }
        )
      } else {
        this.searched = false;
        this.isLoading = false;
        this.data = [];
      }
    });
  }

  saveInLocalStorage(item) {
    localStorage.setItem(item.id, item.title)
  }

  existInLocalStorage(item) {
    return localStorage.getItem(item.id);
  }

  removeFromLocalStorage(item) {
    localStorage.removeItem(item.id);
  }
}
