import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SearchBoxComponent } from './search/search-box/search-box.component';
import { SearchResultsListComponent } from './search/search-results-list/search-results-list.component';
import { SearchComponent } from './search/search.component';
import { HomeComponent } from './home/home.component';
import { MatInputModule } from '@angular/material/input';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatCardModule, MatChipsModule, MatIconModule, MatProgressSpinnerModule} from '@angular/material';
import {IssueService} from './shared/service/issue.service';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    SearchBoxComponent,
    SearchResultsListComponent,
    SearchComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MatInputModule,
    MatCardModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  providers: [IssueService],
  bootstrap: [AppComponent]
})
export class AppModule { }
