import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../environments/environment';


@Injectable()
export class IssueService {

  private searchQuery = new BehaviorSubject<string>('');

  cast = this.searchQuery.asObservable();

  constructor(private httpClient: HttpClient) {}

  editSearchQuery(newSearchQuery) {
    if(newSearchQuery.trim() !== this.searchQuery.value) {
      this.searchQuery.next(newSearchQuery.trim());
    }
  }

  getIssues(query) {
    return this.httpClient.get(environment.apiUrl + '?q=' + query + "+state:open");
  }

}
