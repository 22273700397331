import { Component, OnInit } from '@angular/core';
import {IssueService} from '../../shared/service/issue.service';
import { Debounce } from 'lodash-decorators';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.sass']
})
export class SearchBoxComponent implements OnInit {

  constructor(private issueService: IssueService) { }

  ngOnInit() {
  }

  @Debounce(1000)
  applyFilter(event: any) {
    this.issueService.editSearchQuery(event.target.value);
  }
}
